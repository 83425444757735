<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
        <template v-if="canSyncLdap() && $store.state.data.status.ldapEnabled">
          <span :id="`BTN_SYNC_${id}`">
            <b-btn @click="syncLdap"><font-awesome-icon :icon="['far', 'down-to-bracket']"/></b-btn>
          </span>
          <b-popover :target="`BTN_SYNC_${id}`" triggers="hover" placement="top">
            {{ $t('button.sync_ldap') }}
          </b-popover>
        </template>
        <span @[colorMouseEnterEvent]="onColoringOver" @mouseleave="onColoringLeave">
          <b-dropdown :id="`BTN_COLORING_${id}`" ref="coloring" class="action-bar-dropdown" toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <font-awesome-icon :icon="['far', 'palette']"/>
            </template>
            <b-dropdown-group :header="$t('colorby')">
              <b-dropdown-item @click="onColorChange('none')" href="#">
                <span class="action-item-label">{{ $t('none') }}</span><font-awesome-icon class="active-check" v-if="coloring.none" :icon="['far', 'check']"/>
              </b-dropdown-item>
              <b-dropdown-item @click="onColorChange('user')" href="#">
                <span class="action-item-label">{{ $t('user.coloring.user') }}</span><font-awesome-icon class="active-check" v-if="coloring.user" :icon="['far', 'check']"/>
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </span>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="user-grid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            :overlayNoRowsTemplate="overlayNoRowsTemplate"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :rowData="rowData"
            rowModelType="serverSide"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <UserModal v-if="userShow" :id="userId" :show.sync="userShow" @success="userSuccess" :title="userTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('user.field.email')" label-for="duplicateEmail">
        <b-input-group>
          <b-form-input id="duplicateEmail" type="text"
            :data-vv-as="$t('user.field.email')"
            data-vv-name="duplicateEmail"
            data-vv-delay="500"
            v-model="duplicateEmail"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateEmailError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateEmail') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('user.field.firstName')" label-for="duplicateFirstname">
        <b-input-group>
          <b-form-input id="duplicateFirstname" type="text"
            v-model="duplicateFirstname"/>
        </b-input-group>
      </b-form-group>

      <b-form-group :label="$t('user.field.lastName')" label-for="duplicatLastname">
        <b-input-group>
          <b-form-input id="duplicatLastname" type="text"
            :data-vv-as="$t('user.field.lastName')"
            data-vv-name="duplicateLastname"
            data-vv-delay="500"
            v-model="duplicateLastname"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateLastnameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicateLastname') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('user.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        <template v-if="selectedWithStaff.length > 0">
          <template v-if="selectedWithStaff.length > 1">
            <p>{{ $t('user.confirmation.delete_plural_staff_link_warning_line1') }}</p>
            <ul>
              <template v-for="(item, index) in selectedWithStaff">
                <li :key="index">{{item.staffEmail[0] }}</li>
              </template>
            </ul>
            <p>{{ $t('user.confirmation.delete_plural_staff_link_warning_line2') }}</p>
          </template>
          <template v-else>
            <p>{{ $t('user.confirmation.delete_staff_link_warning_line1', [selectedWithStaff[0].staffEmail[0]]) }}</p>
            <p>{{ $t('user.confirmation.delete_staff_link_warning_line2') }}</p>
          </template>
        </template>
        <p>{{ $t(selected.length > 1? 'user.confirmation.delete_plural':'user.confirmation.delete') }}</p>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('user.confirmation.title_delete')"
        v-model="confirmDeleteSelfShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ this.$t('user.confirmation.delete_self') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    <b-modal :title="$t('user.confirmation.title_cannot_delete')"
        v-model="confirmCannotDeleteShow"
        ok-only
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ cannotDeleteReason }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="danger" @click="ok()">{{ $t('button.ok') }}</b-button>
      </template>
    </b-modal>
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common';
import { strRandom, invertColor } from '@/helpers';
import { userService, viewProfileService, userLinkPermissionService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';

import ListFilter from '@/components/ListFilter/ListFilter';
// import UserModal from '@/components/modal/UserModal';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import StatusCellRenderer from '@/components/Aggrid/CellRenderer/Status';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      userService.list(self.buildParams(params), self.entity ? { includeAccessPolicy:false, includeDataRules:true } : {}).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        if(self.gridApi && self.modalShow) {
          self.gridApi.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            self.gridApi.forEachNode(function(node) {
              if (selected.includes(node.data.uuId)) {
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
          }
          
          if(self.preSelected && self.preSelected.length > 0) {
            self.gridApi.forEachNode(function(node) {
              if ((node.data.dataRules.length === 1 && (node.data.dataRules[0].length === 0 || node.data.dataRules[0] === '[]')) ||
                  ((node.data.dataRules[0].includes(' eq ') || node.data.dataRules[0].includes(' within ')) && 
                   -1 !== self.preSelected.findIndex(f => node.data.dataRules[0].includes(f.uuId)))) {
                node.setSelected(true);
              }
            });
          }
        }
        self.gridApi.hideOverlay();
        
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
        params.successCallback([], self.totalRecords);
        self.gridApi.showNoRowsOverlay();
      });
    }
  }
}

export default {
  name: 'UserSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    UserModal: () => import('@/components/modal/UserModal.vue'),
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    InProgressModal: () => import('@/components/modal/InProgressModal'),
    'detailLinkCellRenderer': DetailLinkCellRenderer, // eslint-disable-line vue/no-unused-components
    'colorCellRenderer': ColorCellRenderer, // eslint-disable-line vue/no-unused-components
    'statusCellRenderer': StatusCellRenderer // eslint-disable-line vue/no-unused-components
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    },
    entity: {
      type: String,
      default: null
    },
    preSelected: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      id: `USER_LIST_${strRandom(5)}`,
      inProgressShow: false,
      inProgressLabel: null,
      inProgressStoppable: false,
      inProgressState: {
        cancel: false
      },
      permissionName: 'USER',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: [],
      context: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],

      userId: null,
      userShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      confirmCannotDeleteShow: false,
      confirmDeleteSelfShow: false,
      cannotDeleteReason: null,
      totalRecords: 0,
      
      searchFilter: '',

      duplicateShow: false,
      duplicateEmail: null,
      duplicateFirstname: null,
      duplicateLastname: null,
      duplicateInProgress: false,
      
      settings: {},
      
      coloring: {
        none: true,
        user: false
      },
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    this.loginUserId = this.$store.state.authentication.user.uuId;
    const profileKey = 'user_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    const colDefs = [
      {
        headerName: this.$t('user.field.lastName'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false,
        sort: 'asc',
        cellStyle: params => {
            if (params.data &&
                params.data.color &&
                self.coloring.user) {
                return { background: params.node.data.color, color: invertColor(params.node.data.color, true) };
            }
        }
      },
      {
        headerName: this.$t('user.field.firstName'),
        field: 'firstName',
        hide: false
      },
      {
        headerName: this.$t('user.field.nickName'),
        field: 'nickName',
        hide: true
      },
      {
        headerName: this.$t('user.field.email'),
        field: 'email',
        hide: false
      },
      {
        headerName: this.$t('field.tag'),
        field: 'tag',
        minWidth: 100
      },
      {
        headerName: this.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer'
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100
      },
      {
        headerName: this.$t('user.field.ldapLogin'),
        field: 'ldap',
        minWidth: 100,
        valueGetter: params => {
          return params.data.ldap ? this.$t('user.field.ldapLogin') : '';
        }
      },
      {
        headerName: this.$t('field.status'),
        field: 'enabled',
        cellRenderer: 'statusCellRenderer',
        minWidth: 100
      }
    ];
    
    const linkedEntities = [
      { selector: 'USER.TAG', field: 'tag', properties: ['name'] }
    ]
    this.$store.dispatch('data/schemaAPI', {type: 'api', opts: 'brief' })
    .finally(() => {
      filterOutViewDenyProperties(colDefs, 'USER', linkedEntities);
      colDefs.sort(columnDefSortFunc);
      this.columnDefs = colDefs;
    });

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };

    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.isUserModalRendered = false;
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.newToProfile = null;
    this.isUserModalRendered = null;
    this.loginUserId = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        this.searchFilter = "";
        await this.loadViewProfile();
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    },
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    userTitle() {
      return this.userId && this.userId.indexOf('USER_NEW') == -1? this.$t('user.user_edit'): this.$t('user.title_new');
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    selectedWithStaff() {
      if(this.gridApi) {
        const nodes = this.selected.map(i => this.gridApi.getRowNode(i)).filter(i => i != null);
        return nodes.filter(i => i.data.staffEmail && i.data.staffEmail.length > 0).map(j => j.data);
      }
      return [];
    },
    selectedHasCurrentUser() {
      const userId = this.$store.state.authentication.user.uuId;
      return this.selected.filter(id => id == userId).length > 0;
    },
    selectedHasSystemAdmin() {
      if(this.gridApi) {
        const nodes = this.selected.map(i => this.gridApi.getRowNode(i)).filter(i => i != null);
        return nodes.filter(i => i.data.superUser).length > 0;
      }
      return false;
    },
    labelTitle() {
      return this.title? this.title : this.$t('user.title_selector');
    },
    showDuplicateEmailError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateEmail');
    },
    showDuplicateLastnameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicateLastname');
    },
    duplicateTitle() {
      return this.$t('user.title_duplicate');
    },
    colorMouseEnterEvent() {
      return this.isTouchDevice()? null : 'mouseenter';
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
    
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      
      params.entity = this.entity;
      
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.userId = `USER_NEW_${strRandom(5)}`;
      } else {
        this.userId = this.selected[0];
      }
      this.userShow = true;
      this.resetAlert();
    },
    userSuccess(payload) {
      this.gridApi.refreshServerSide({ purge: true });
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      if (this.selectedHasCurrentUser) {
        this.confirmDeleteSelfShow = true;
      } else if (this.selectedHasSystemAdmin) {
        this.cannotDeleteReason = this.$t('user.confirmation.cannot_delete_system_admin');
        this.confirmCannotDeleteShow = true;
      } else {
        this.confirmDeleteShow = true;
      }
    },
    async confirmDeleteOk(){ 
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('user.progress.deleting');
      const selectedNodes = this.gridOptions.api.getSelectedNodes();
      const toDeleteIdNames = selectedNodes.map(node => { return { uuId: node.data.uuId, name: node.data.name != null? node.data.name : node.data.label } });
      const toDeleteIds = this.selected.map(i => { return { uuId: i } });
      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`user.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      await userService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('user.delete_partial');
          alertMsgDetailTitle = this.$t(`user.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            const targetId = toDeleteIds[i].uuId;
            if (feedback.clue == 'OK') {
              continue;
            }
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`user.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`user.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.gridApi.refreshServerSide({ purge: true });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.inProgressShow = false;
      this.resetAlert(alertPayload);
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      if (this.entity) {
        this.gridApi.forEachNode(node => {
          const d = node.data;
          if (node.selected) {
            if (d.dataRules[0] !== '' && d.dataRules[0] !== '[]') {
              if (d.dataRules[0].includes(' eq ') &&
                  this.preSelected.filter(r => !d.dataRules[0].includes(r => r.uuId).length !== 0)) {
                let rule = d.dataRules[0].match(/[\\"(.+?)\\"]/)[1];
                let id = rule.substr(rule.lastIndexOf(' ') + 1, rule.length - 2);
                let ids = [...this.preSelected];
                ids.push(id);
                d.dataRules[0] = `.uuId within ${ids.join('|')}`;  
              }
              else if (d.dataRules[0].includes(' within ') &&
                  this.preSelected.filter(r => !d.dataRules[0].includes(r => r.uuId).length !== 0)) {
                let rule2 = d.dataRules[0].match(/\["(.+?)"\]/)[1];
                let id2 = rule2.substr(rule2.lastIndexOf(' ') + 1, rule2.length - 2);
                let ids2 = [...this.preSelected.filter(r => !d.dataRules[0].includes(r => r.uuId)).map(pre => pre.uuId)];
                ids2.push(...id2.split('|'));
                d.dataRules[0] = `.uuId within ${ids2.join('|')}`;  
              }
              userLinkPermissionService.update(d.uuId, [{ uuId: d.permUuId[0], permissionLink: { dataRules: d.dataRules } }]);
            }
          }
          else {
            if (d.dataRules[0] !== '' && d.dataRules[0] !== '[]') {
              if (d.dataRules[0].includes(' eq ') &&
                  this.preSelected.filter(r => d.dataRules[0].includes(r => r.uuId).length === 1)) {
                d.dataRules.splice(0, 1);  
              }
              else if (d.dataRules[0].includes(' within ') &&
                  this.preSelected.filter(r => d.dataRules[0].includes(r => r.uuId).length !== 0)) {
                let rule2 = d.dataRules[0].match(/\["(.+?)"\]/)[1];
                let id2 = rule2.substr(rule2.lastIndexOf(' ') + 1, rule2.length - 2);
                let ids2 = id2.split('|');
                let toRemove = this.preSelected.filter(r => d.dataRules[0].includes(r => r.uuId)).map(pre => pre.uuId);
                for (const r of toRemove) {
                  const idx = ids2.findIndex(i => i === r);
                  if (idx !== -1) {
                    ids2.splice(idx, 1);
                  }
                }
                
                if (ids2.length !== 0) {
                  d.dataRules[0] = `.uuId within ${ids2.join('|')}`;
                }
                else {
                  d.datarules.splice(0, 1);
                }  
              }
              userLinkPermissionService.update(d.uuId, [{ uuId: d.permUuId[0], permissionLink: { dataRules: d.dataRules } }]);
            }
          }
        });
      }
      
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: `${i.data.firstName} ${i.data.lastName}`, dataRules: i.data.dataRules, permUuId: i.data.permUuId } });
      this.$emit('ok', { ids: [...this.selected], details: details });
      this.$emit('input', [...this.selected]);
      
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
    },
    openDetail(id){
      this.userId = id;
      this.userShow = true;
      this.resetAlert();
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      this.gridApi.refreshServerSide({ purge: true });
    },
    showDuplicateDialog() {
      this.resetAlert();
      const data = this.gridApi.getRowNode(this.selected[0]).data;
      this.duplicateFirstname = data.firstName;
      this.duplicateLastname = data.lastName;
      this.duplicateEmail = '';
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.errors.clear();
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateEmail || this.duplicateEmail.trim().length < 1) {
        this.errors.add({
          field: `duplicateEmail`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.email')])
        });
      }
      if(!this.duplicateLastname || this.duplicateLastname.trim().length < 1) {
        this.errors.add({
          field: `duplicateLastname`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('staff.field.lastName')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          userService.clone(this.selected[0], { 
            email: this.duplicateEmail
            , firstName: this.duplicateFirstname
            , lastName: this.duplicateLastname
          })
          .then(() => {
            this.resetAlert({ msg: this.$t('user.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.USER')]);
            if(e.response && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              let signal = false;
              if ('Not_unique_key' === clue) { 
                alertMsg = this.$i18n.t('user.error.duplicate_value', [this.$i18n.t('user.field.email')]);
                this.errors.add({
                  field: `duplicateEmail`,
                  msg: this.$i18n.t('user.error.duplicate_value', [this.$i18n.t('user.field.email')])
                });
                signal = true; //Set true to signal not to close dialog.
              } else if ('Unknown_holder' === clue) {
                alertMsg = this.$t('user.error.duplicate_not_found');
              }
              else if ('user.licence.limit' === e.response.data.jobClue.hint) {
                alertMsg = this.$t('user.error.user_limit_exceeded');
                signal = true; //Set true to signal not to close dialog.
              }
              
              if (!signal) {
                //Only show alert feedback when dialog is ready to be closed.
                this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              }
              this.scrollToTop();
              return signal;
            } else {
              this.httpAjaxError(e);
            }
          })
          .then(doNotCloseDialog => {
            if(!doNotCloseDialog) {
              this.duplicateShow = false;
              this.errors.clear();
            }
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.loginUserId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.loginUserId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.loginUserId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.user_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.user_selector_list);
            self.coloring.none = self.settings.user_coloring ? self.settings.user_coloring.none : true;
            self.coloring.user = self.settings.user_coloring ? self.settings.user_coloring.user : false;
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    onColoringOver() {
      this.$refs.coloring.visible = true;
    },
    onColoringLeave() {
      this.$refs.coloring.visible = false;
    },
    isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
      const mq = function (query) {
          return window.matchMedia(query).matches;
      }
      if ('ontouchstart' in window) {
          return true;
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
      return mq(query);
    },
    onColorChange(val) {
      for (const key of Object.keys(this.coloring)) {
        this.coloring[key] = false;
      }
      this.coloring[val] = true;
      this.settings['user_coloring'] = this.coloring;
      this.updateViewProfile();
      this.gridApi.redrawRows();
    },
    getRowColor(data) {
      if (data &&
        data.color &&
        this.coloring.user) {
        return data.color;
      }
    },
    syncLdap() {
      const self = this;
      this.inProgressShow = true;
      this.inProgressLabel = this.$t('user.progress.sync_ldap');
      userService.syncLdap().then(() => {
        this.gridApi.refreshServerSide({ purge: true });
        this.inProgressShow = false;
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
        self.inProgressShow = false;
      });
    }
  }
}


</script>